<template>
  <div class="container">
    <img src="./assets/logo_main.svg" alt="Main Logo" class="logo" />
    <div class="buttons">
      <a href="https://t.me/Work_mb" class="btn" target="_blank">
        <img src="./assets/telegram_logo.png" alt="Telegram" class="icon" />
        Telegram
      </a>
      <a href="https://..." class="btn" target="_blank">Демо-приложение</a>
      <a href="https://thapl.com" class="btn" target="_blank">Перейти на сайт</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.btn {
  background-color: #66f;
  color: white;
  text-decoration: none;
  padding: 15px 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: background-color 0.3s ease;
  font-family: 'Montserrat', sans-serif;
}

.btn:hover {
  background-color: #559;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
</style>